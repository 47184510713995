import { Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SingleConfigGQL, SingleConfigQuery } from 'src/graphql/generated';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: []
})
export class HelpdeskComponent implements OnInit {
  data$: Observable<SingleConfigQuery['_config_by_pk']>

  constructor(
    private configService: SingleConfigGQL
  ) { 
    this.data$ = this.configService.fetch({ id: 'HELPDESK'}).pipe(map(d => d.data._config_by_pk))
  }

  ngOnInit(): void {

  }

}
