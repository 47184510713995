import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { LocalStorageService } from 'src/app/local-storage.service';
import { RequestModel } from 'src/app/models/request.model';
import { MakeRequestGQL } from 'src/graphql/generated';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  static URL = '/api/v1/request';

  constructor(
    public http: HttpClient, 
    private localstorageService: LocalStorageService,
    private makeReqwuestService: MakeRequestGQL
  ) { }

  create(data: RequestModel) {
    let shouldMock = false
    try {
      let lsData = this.localstorageService.getItem('mockQueries', [])
      shouldMock = lsData.includes(data._dataChannel) || lsData.includes('*')
    } catch (err) {

    }
    return this.makeReqwuestService.mutate({
      request: { ...data },
      justPrepare: false
    }).pipe(
      map(x => x.data?.submitRequest)
    )
  }

  oldCreate(data: RequestModel) {
    let shouldMock = false
    try {
      let lsData = this.localstorageService.getItem('mockQueries', [])
      shouldMock = lsData.includes(data._dataChannel) || lsData.includes('*')
    } catch (err) {

    }
    return this.http.post(RequestService.URL, { ...data, _mock: shouldMock });
  }


}
