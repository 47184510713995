import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, timeout } from 'rxjs';
import { LocalStorageService } from 'src/app/local-storage.service';
import { QsGQL } from 'src/graphql/generated';

export class QueryPostData {
  channel!: string
  data: any
  mock?: boolean = false
  trackingId?: string
}

export class QueryPostResult {
  items?: any[]
  error?: { code: number, message: string, raw: any }
  [key: string]: any
}
@Injectable({
  providedIn: 'root'
})
export class QueriesService {
  static URL = '/api/v1/queries';

  constructor(
    public http: HttpClient, 
    private localstorageService: LocalStorageService,
    private qService: QsGQL) { }

  post<T = QueryPostResult>(data: QueryPostData): Observable<T> {
    let shouldMock = data.mock || false
    try {
      let lsData = this.localstorageService.getItem('mockQueries', [])
      shouldMock = lsData.includes(data.channel) || lsData.includes('*')
    } catch (err) {

    }
    // return this.http.post<T>(QueriesService.URL, { ...data, mock: shouldMock }).pipe(timeout(30000));
    return this.qService.mutate({...data, mock: shouldMock}).pipe(
      timeout(30000),
      map(d => d.data?.query as T)
    )
  }

  postTextResponse(data: QueryPostData): Observable<any> {
    let shouldMock = data.mock || false
    try {
      let lsData = this.localstorageService.getItem('mockQueries', [])
      shouldMock = lsData.includes(data.channel) || lsData.includes('*')
    } catch (err) {

    }
    return this.http.post(QueriesService.URL, data, { responseType: 'text' }).pipe(timeout(30000))
  }
}
