<!-- ====== Contact Section Start -->
<section class="px-6 py-20 lg:py-[120px] overflow-hidden relative z-10">
    <div class="container">
        <div class="flex flex-wrap lg:justify-between -mx-4">
            <div class="w-full lg:w-1/2 xl:w-6/12 px-4 text-dark dark:text-white">
                <div class="max-w-[570px] mb-12 lg:mb-0">
                    <span class="block mb-4 text-base text-primary font-semibold">
                        Assistenza
                    </span>
                    <h2 class="
                   mb-6
                   uppercase
                   font-bold
                   text-[32px]
                   sm:text-[40px]
                   lg:text-[36px]
                   xl:text-[40px]
                   ">
                        Serve aiuto?
                    </h2>
                    <p class="text-base text-body-color leading-relaxed mb-9">
                        Ecco come puoi metterti in contatto con noi
                    </p>
                    <div class="" *ngIf="data$ | async as data">
                        <h4 class="text-xl text-primary py-2">{{data.value?.title}}</h4>


                        <ul class="">
                            <li class="mb-2">
                                <a href="#" class="flex items-center justify-start gap-2">
                                    <i class="fas fa-phone-alt w-5 h-5 text-primary bg-gray-50 rounded-full p-2"></i>
                                    <span class="text-xl">{{data.value?.phone}}</span>
                                </a>
                            </li>
                            <li class="mb-2">
                                <a href="#" class="flex items-center justify-start gap-2">
                                    <i class="fas fa-envelope w-5 h-5 text-primary  bg-gray-50 rounded-full p-2"></i>
                                    <span class="text-xl">{{data.value?.email}}</span>
                                </a>
                            </li>
                            <li class="">
                                <a href="#" class="flex items-center justify-start gap-2">
                                    <i
                                        class="fas fa-map-marker-alt w-5 h-5 text-primary bg-gray-50 rounded-full p-2"></i>
                                    <span class="text-xl">{{data.value?.address}}</span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/2 xl:w-5/12 px-4 invisible">
                <div class="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
                    <form>
                        <div class="mb-6">
                            <input type="text" placeholder="Your Name" class="
                         w-full
                         rounded
                         py-3
                         px-[14px]
                         text-body-color text-base
                         border border-[f0f0f0]
                         outline-none
                         focus-visible:shadow-none
                         focus:border-primary
                         " />
                        </div>
                        <div class="mb-6">
                            <input type="email" placeholder="Your Email" class="
                         w-full
                         rounded
                         py-3
                         px-[14px]
                         text-body-color text-base
                         border border-[f0f0f0]
                         outline-none
                         focus-visible:shadow-none
                         focus:border-primary
                         " />
                        </div>
                        <div class="mb-6">
                            <input type="text" placeholder="Your Phone" class="
                         w-full
                         rounded
                         py-3
                         px-[14px]
                         text-body-color text-base
                         border border-[f0f0f0]
                         outline-none
                         focus-visible:shadow-none
                         focus:border-primary
                         " />
                        </div>
                        <div class="mb-6">
                            <textarea rows="6" placeholder="Your Message" class="
                         w-full
                         rounded
                         py-3
                         px-[14px]
                         text-body-color text-base
                         border border-[f0f0f0]
                         resize-none
                         outline-none
                         focus-visible:shadow-none
                         focus:border-primary
                         "></textarea>
                        </div>
                        <div>
                            <button type="submit" class="
                         w-full
                         text-white
                         bg-primary
                         rounded
                         border border-primary
                         p-3
                         transition
                         hover:bg-opacity-90
                         ">
                                Send Message
                            </button>
                        </div>
                    </form>
                    <div>
                        <span class="absolute -top-10 -right-9 z-[-1]">
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                                    fill="#3056D3" />
                            </svg>
                        </span>
                        <span class="absolute -right-10 top-[90px] z-[-1]">

                        </span>
                        <span class="absolute -left-7 -bottom-7 z-[-1]">

                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ====== Contact Section End -->